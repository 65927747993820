<template>
  <div>
    <b-modal
		id="parent_child_venue"
		:no-close-on-backdrop="true"
		size="sm"
		:hide-footer="true"
		:centered="true"
        ref="parent_child_venue"
        title="Please Select Option"
      header-bg-variant="primary"
      header-text-variant="light"
      cancel-variant="closeChild"
		>
    <button type="button" class="close custom-close-button" data-dismiss="modal" @click="closeChild()">
              &times;
            </button>
		<!-- 	<h5 class="text-center my-4">Please Select Option</h5> -->
			<div class="d-flex justify-content-around">
				<!-- <b-button variant="outline-primary" @click="createChild()"
				>Create Child Venue</b-button
				> -->
                <b-button variant="success" @click="createparent()"
				>Create Parent </b-button
				>
				<b-button variant="secondary" @click="createChild()"
				>Create Child </b-button
				>

			</div>
            <div v-if="parent_list" class="d-flex justify-content-around mt-3">
            <multiselect
              v-model="parent_selected"
              :options="parent_option"
              placeholder="Select Parent Venue"
              track-by="name"
              label="name"
              :close-on-select="true"
              select-label=""
              deselect-label=""
              preselect-first="true"
            >
            </multiselect>
            <b-button variant="secondary ml-2"  @click="target_selectChild()"
				>Create</b-button
				>
          
            </div>

		</b-modal>
  </div>
</template>

<script>
  import axios from "axios";
  import Multiselect from "vue-multiselect";
 export default {
    data: () => ({
        parent_option:[],
        parent_list:false,
        parent_selected:''
    }),
    components: {
        Multiselect
    },
    methods: {
      showModal() {
        this.$refs['parent_child_venue'].show()
      },
      hideModal() {
        this.$refs['parent_child_venue'].hide()
      },
      createparent()
      {
        this.$router.push("/venues/add-parent-venue");
      },
       
      closeChild()
      {
        this.$router.push("/venues");
      },
      createChild()
      {
        this.parent_list=true;
        this.parentVenueList();
      },
      parentVenueList() {
      axios.get("/api/parent_venue_listing").then((resp) => {
        if (resp.data.status_code == 200) {
          if (resp.data.status) {
            for (var i = 0; i < resp.data.parent_venue_details.length; i++) {
              this.parent_option.push({
                value: resp.data.parent_venue_details[i].parent_venue_id,
                name: resp.data.parent_venue_details[i].venue_name,
              });
            }
          }
        }
      });
    },
    target_selectChild()
    {
        this.$router.push("/venues/Child_venue/index/" + this.parent_selected.value);
        
    }
    },
    mounted() {
       this.showModal();
      // this.parentVenueList();
    }
  }
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.modal-header .close {
  display:none;
}
.custom-close-button
{
  position: absolute;
    top: -39px;
    right: 18px;
    color: white;
}
</style>